<template>
    <b-card no-body class="card-developer-meetup">
      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/illustration/email.svg')"
          alt="Meeting Pic"
          height="170"
        />
      </div>
      <b-card-body>
        <!-- metting header -->
        <validation-observer ref="simple">
        <b-card-code>
          <strong class="text-danger mb-20" v-if="data.length == 0">لا يوجد ردود</strong>
            <v-row accordion v-for="item in data" v-if="data.length > 0">
    <h3 :title="`تم الرد من قبل ${item.user_id} في تاريخ ${item.created_at}`" class="show"></h3>
    <p>
      {{item.body}}
   
   
    </p>
  </v-row >
<br />
<br />
<br />
  <validation-provider #default="{ errors }" rules="required" class="mt-2">
    <b-form-group label-for="notes" :state="errors.length > 0 ? false : null">
      <b-form-textarea
        id="textarea-default"
        placeholder="أضافة رد"
        v-model="problemBody"
        
        rows="2"
      />
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
        
        </b-card-code>
        <br />
    
        
        <b-button variant="purple" @click="save"    v-if="!isLoading">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">رد على الشكوى</span>
        </b-button>
        <b-button v-if="isLoading"  variant="purple" disabled class="mr-1">
                Loading...
             
                </b-button>
      </validation-observer>
      </b-card-body>
      <b-col class="col-2 text-align-start mb-2">
  
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="$router.go(-1)">
          <span class="align-middle">رجوع</span>
        </b-button>
      </b-col>
  
    </b-card>
  
  </template>
  
  <script>
  import vSelect from "vue-select";
  import { required, max, is_earlier } from "@validations";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import {
    BCard,
    BFormGroup,
  
    BImg,
    BButton,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BForm,
    BMediaBody,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  /* eslint-disable global-require */
  export default {
    components: {
      required,
      AppCollapseItem,
      AppCollapse,
      BFormInvalidFeedback,
      ValidationProvider, ValidationObserver,
      BCard,
      BRow,
      BFormInput,
    BFormTextarea,
    BCol,
      vSelect,
      BForm,
      BInputGroupPrepend,
    BInputGroup,
      BCardCode,
      BFormGroup,
      BFormTextarea,
      BButton,
      BImg,
      BCardBody,
      BCardText,
      BCardTitle,
      BMedia,
      BMediaAside,
      BAvatar,
      BAvatarGroup,
      BMediaBody,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userName: "",
        problemBody: "",
        beneficaryName:'',
        beneficaryNumber:'',
        isLoading:false,
        selectedRel:'شخص مسجل ',
        CardNumber:'',
        beneficaryId:'',
        service:'',
        serviceList:[],
        data:[
      ],
        beneficaryList:[],
        getBeneficiaryByCard:false,
        optionPerson:[
          {label:'شخص مسجل ', value:1},
          {label:'شخص غير مسجل ', value:2}
        ]
      };
    },
  
    directives: {
      "b-tooltip": VBTooltip,
    },
  
    created() {
      this.userName = localStorage.getItem("userName");
      let url = `/api/v1/complains/${this.$route.params.id}`;

this.$http.get(url).then((res) => {
  //console.log(res.data.data);
  this.data=res.data.data.replies



});
      this.$http
          .get(`/api/v1/main_services`)
          .then((res) => {
          //console.log(res.data.data)
          this.serviceList=res.data.data
          })
          .catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
              title: `${error.response.data.message}`,
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
    },
    methods: {
      save() {
        this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
                this.isLoading=true
        let user = JSON.parse(localStorage.getItem("userData"));
        let id =null
        if(this.data.length>0){
          id=this.data[0].parent_id
        }
        else{
          id=this.$route.params.id
        }
        let data = {
          parent_id:id,
       body: this.problemBody,
       user_id: user.id,
        };
        //console.log(data);
  
        this.$http
          .post(`/api/v1/complains`, data)
          .then((res) => {
            this.isLoading=false
            this.problemBody=''
            requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
            this.$swal({
              title: "تم الرد على الشكوى بنجاح",
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.isLoading=false
            this.$swal({
              title: `${error.response.data.message}`,
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }})
      },
     replyComplains (id) {
    //     person_id:id,
    //    body: result.value,
    //    user_id: user.roles[0].id ,
    //   let user=JSON.parse(localStorage.getItem('userData'))
    
      
       
     
      }
      
 
    },
  };
  </script>
  
  <style scoped>
  label {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    width: 200px;
    background-color: #00468e !important;
  }
  </style>
  